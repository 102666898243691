import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../layouts/index';
import SEO from '../components/SEO';
import PostSlices from '../components/PostSlices';

const Post = ({ data: { prismicPost } }) => {
  const { data,uid } = prismicPost;


  return (
    <Layout bodyClass="page-post">
      <SEO title={data.title.text} pathname={`/posts/${uid}`} image={data.seo_image}/>
      <div className="intro">
        <div className="container">
          <div className="h1 text-primary title text-center">{data.title.text}</div>
        </div>
      </div>
      <PostSlices allSlices={data.body}/>
      <div className="m-5"></div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
  prismicPost(id: {eq: $id}, type: {}) {
    id
    uid
    data {
      date
      title {
        text
      }
      description
      categories {
        category {
          id
        }
      }
      seo_image {
        thumbnails {
          twitter {
            url
          }
          facebook {
            url
          }
        }
        dimensions {
          width
          height
        }
        url
      }
      body {
        __typename
        ... on PrismicPostDataBodyImage {
          slice_type
          id
          primary{ 
            image {
              url
            }
          }
        }
        ... on PrismicPostDataBodyCodeBlock {
          slice_type
          id
          primary {
            code_block {
              raw
              html
            }
          }
        }
        ... on PrismicPostDataBodyImageCarousel {
          slice_type
          id
          items {
            image {
              url
            }
            caption {
              text
            }
            label {
              html
            }
          }
        }
        ... on PrismicPostDataBodyContact {
          id
          slice_type
          primary {
            email
            form_name
            message
            name
          }
        }
        ... on PrismicPostDataBodyContentBlock {
          id
          slice_type
          primary {
            image {
              gatsbyImageData
              url
              alt
            }
            image_location
            text {
              html
              raw
            }
          }
        }
        ... on PrismicPostDataBodyYoutubevideo {
          id
          slice_type
          primary {
            url {
              url
            }
          }
        }
        ... on PrismicPostDataBodyJumbotron {
          id
          slice_type
          primary {
            title
            subheading
          }
        }
        ... on PrismicPostDataBodyHardReturn {
          slice_type
        }
        ... on PrismicPostDataBodyText {
          id
          slice_type
          primary {
            text {
              html
            }
          }
        }
      }
    }
  }
}

`;

export default Post;
